<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md8>
          <h1 class="">MiLady Health Guide App</h1>
          <h2 class="">Content Management System</h2>
          <h3>Version {{ appVersion }} - Release Date: {{ releaseDate }}</h3>
          <v-spacer sm="6"></v-spacer>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Login form</v-toolbar-title>
            </v-toolbar>
            <v-form
              id="loginForm"
              :rules="rules"
              ref="loginForm"
              lazy-validation
              :disabled="isDisabled"
              @submit.prevent="onSubmitlogin"
            >
              <v-card-text>
                <ul v-if="errors" class="error-messages">
                  <li v-for="(v, k) in errors" :key="k">
                    {{ k }} {{ v | error }}
                  </li>
                </ul>
                <v-text-field
                  prepend-icon="person"
                  name="username"
                  v-model="username"
                  label="Username"
                  type="text"
                  :rules="[() => username.length > 0 || 'Username is required']"
                ></v-text-field>
                <v-text-field
                  id="password"
                  prepend-icon="lock"
                  name="password"
                  v-model="password"
                  label="Password"
                  type="password"
                  :rules="[() => password.length > 0 || 'Password is required']"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  :loading="inProgress"
                  :disabled="inProgress"
                  @click="onSubmitlogin"
                  >Login</v-btn
                >
              </v-card-actions>
              <v-snackbar
                v-model="msgbar"
                :value="true"
                bottom
                tile
                :timeout="1000"
                :color="color"
              >
                {{ text }}
                <v-btn @click.native="msgbar = false">Close</v-btn>
              </v-snackbar>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import { LOGIN } from "@/store/actions.type";
import { REMOVE_MASTERDATA } from "@/store/mutations.type";
import { version, releaseDate } from "../../package";
export default {
  name: "Login",
  data() {
    return {
      inProgress: false,
      isDisabled: false,
      username: "",
      password: "",
      rules: {
        account: [
          {
            required: true,
            message: "Please enter username",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please enter password",
            trigger: "blur",
          },
        ],
      },
      msgbar: false,
      color: "info",
      timeout: 4000,
      text: "",
      appVersion: version,
      releaseDate: releaseDate,
    };
  },
  methods: {
    onSubmitlogin() {
      var that = this;
      if (that.$refs["loginForm"].validate()) {
        this.inProgress = true;
        this.isDisabled = true;
        this.$store.commit(REMOVE_MASTERDATA);
        this.$store
          .dispatch(LOGIN, { username: this.username, password: this.password })
          .then(() => {
            this.msgbar = true;
            this.color = "success";
            this.text = "Login Successfully!";
            this.$router.push("/");
          })
          .catch((response) => {
            console.log(response);
            this.inProgress = false;
            this.isDisabled = false;
            this.msgbar = true;
            this.color = "red";
            this.text = "Login Failed!";
          });
      }
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>

